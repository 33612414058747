'use strict'
const name = 'BgFadeInOutParallax'
const properties = {
    hideOnStart: false,
    requestFullScreenHeight: true,
    getMaxTravel(elementMeasure, viewportHeight) {
        return viewportHeight + elementMeasure.height
    },
    groups: ['animation', 'background'],
    schema: {
        duration: {
            type: 'number',
            min: 0,
            default: 0
        },
        delay: {
            type: 'number',
            min: 0,
            default: 0
        },
        fadeElements: {
            type: 'elements'
        },
        parallaxElements: {
            type: 'elements'
        },
        parallaxParent: {
            type: 'element'
        }
    }
}

function register({factory}) {
    /**
     * @param {HTMLElement|HTMLElement[]} elements DOM elements to animate
     * @param {Number} [duration]
     * @param {Number} [delay]
     * @param {Object} [params] Timeline optional parameters.
     * @returns {TimelineMax}
     */
    function animation(elements, duration, delay, {
        fadeElements = elements,
        parallaxElements = elements,
        parallaxParent,
        ...params
    } = {}) {
        const sequence = factory.sequence(params)

        sequence.add([
            factory.animate('BaseBgFade', fadeElements, duration, delay, {
                in: {
                    start: 0.1,
                    end: 0.45,
                    opacity: 0,
                    ease: 'sine.in'
                },
                out: {
                    start: 0.75,
                    end: 1,
                    opacity: 0,
                    ease: 'sine.out'
                }
            }),
            factory.animate('BaseBgParallaxY', parallaxElements, duration, delay, {
                parallaxParent,
                speedFactor: 0.15,
                start: 0.15,
                end: 1,
                ease: 'none'
            })
        ])

        return sequence.get()
    }

    factory.registerAnimation(name, animation, properties)
}

module.exports = {
    name,
    properties,
    register
}
